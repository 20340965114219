.loaderInplace.dots {
      /* // position: absolute; */
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      z-index: 1060;
}

.loaderInplace.dots:before {
    content: "•••";
    font-size: 3.6rem;
    animation: loaderAnimation 0.7s infinite ease-in-out alternate-reverse;
}
  
  .loaderWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
  
    
  }

  .loaderWrapper .loader {
    position: absolute;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    
  }

  .loaderWrapper .loader:before {
    content: "•••";
    font-size: 3.6rem;
    animation: loaderAnimation 0.7s infinite ease-in-out alternate-reverse;
  }
  
  
  @keyframes loaderAnimation {
    0% {
      transform: scale(0.3);
      color: rgb(49, 163, 15);
    }
    25% {
      transform: scale(0.5);
      color: rgb(241, 238, 15);
      content: "•";
    }
    50% {
      transform: scale(0.7);
      color: rgb(49, 163, 15);
      content: "•••";
    }
    75% {
      transform: scale(0.9);
      color: rgb(241, 238, 15);
      content: "•";
    }
    100% {
      transform: scale(1);
      color: rgb(49, 163, 15);
    }
  }
  