body {
  /* font-family: "Pacifico", cursive; */
  /* background: url("https://doableyo.com/tc-assets/images/tc-bg.png") 0 0
    no-repeat; */
  /* background-size: cover; */
  background: url("https://doableyo.com/tc-assets/images/tc-bg.png") 0 0
    repeat-y;
  overflow-y: auto !important;
}

p,
a,
span,
label,
strong,
h3,
h4,
h5,
h2,
.MuiTypography-body2 {
  /* font-family: "Pacifico", cursive !important; */
  /* font-family: "Russo One", sans-serif !important; */
}

.App {
  /* font-family: "Balsamiq Sans", cursive; */
  font-weight: 300;
  /* rgb(240 255 3 / 50%); */
  /* width: calc(100vw / 0.5); */
  margin: 0 auto;
  /* padding: 0.1rem 1rem; */
  border-radius: 4px;
  min-height: 450px;
  text-shadow: none;
  background: rgb(242, 243, 174 / 8%);
}

.App h1,
.App h1 a,
.App h2 {
  /* font-family: "Russo One", sans-serif !important; */
}

/* Color styling */
.App,
.App h1 a,
a,
label,
.MuiChip-root.MuiChip-outlined {
  /* color: #000; */
  text-shadow: none;
  /* color: #FC9E4F !important; */
}

.App h1 a {
  text-shadow: none;
  color: #fff;
  /* color: #f2f3ae !important; */
}

h2 {
  text-shadow: none;
  color: #fff;
  /* color: #f4442e !important; */
}

/* 
Color pallete 
Opt1 : 
#C4317B
#AC7C7C
#D0BAA8
#EFE4E4

Opt2:
#FCFFA6
#C1FFD7
#B5DEFF
#CAB8FF
*/

:root {
  --headerBgColor :#C4317B;
  --mudBrownColor :#AC7C7C;
  --lightMudBrownColor :#D0BAA8;
  --lightCreamColor :#EFE4E4;
  
  --lightYellowColor :#FCFFA6;
  --lightGreenColor :#C1FFD7;
  --lightBlueColor :#B5DEFF;
  --lightGreenColor :#C1FFD7;
  --lightPurpleColor: #CAB8FF;
  --lightRedishColor :#ffb8bc;

  --headerHeight: 100px;
}

.header {
  background: var(--headerBgColor);
  background: linear-gradient(90deg, #000 0%, var(--mudBrownColor) 100%);
  min-height: var(--headerHeight);
  display: flex;
  align-items: center;
  align-content: flex-start;
  /* border-bottom: 1px solid var(--mudBrownColor); */
  border-bottom: 1px solid #3A2A2A;
  padding: 0 2rem;

}

.logo {
  margin-bottom: 0;
}

.App h1 a:hover {
  text-decoration: none;
}

.AutoRotatingCarousel-root-2,
.jss2 {
  position: static !important;
  clear: both;
}

div[class^="AutoRotatingCarousel-dots-"],
div[class^="AutoRotatingCarousel-footer"],
.jss13 {
  display: none !important;
}

.carousel {
  margin-bottom: 2.4rem;
}
.carousel .carousel-inner {
  border-radius: 15px;
  margin: 1.2rem 0;
  color: #111;
  clear: both;
  overflow: hidden;
}

.card {
  padding: 0.9rem 0 0;
}
.avatar {
  margin: 0 auto;
  width: auto !important;
  height: 130px;
}

.main-content-view {
  min-height: 50.8vh;
  /* filter: hue-rotate(30); */
   /* opacity(.8); */
  margin: 1rem auto 0 !important;
}

.main-content-view .captain-section {
  min-height: 100vh;
  margin: 0 0 5rem;
  padding: 1.4rem 0;
}
.main-content-view .captain-section .card {
  min-height: 49.3vh;
}

.main-content-view .captain-section .captain-section-tile {
  margin-bottom: 2rem;
}


.section-common
{
  /* min-height: 60vh; */
  /* padding: 2rem; */
  padding: 0;
  background: var(--lightCreamColor);
  background: linear-gradient(90deg, var(--lightCreamColor) 0%, var(--lightMudBrownColor) 100%);

}
.section-login,
.section-add {
  padding: 2rem;
}

.main-content-view .captain-section.section-today{
  min-height:0;
}

.section-today .captain-section-tile .card {
  background: var(--lightYellowColor);
  background: linear-gradient(90deg, var(--lightYellowColor) 0%, var(--lightGreenColor) 100%);
}
.section-upcoming .captain-section-tile .card {
  background: var(--lightGreenColor);
background: linear-gradient(90deg, var(--lightGreenColor) 0%, var(--lightBlueColor) 100%);
}
.section-tobe .captain-section-tile .card {
  background: var(--lightBlueColor);
background: linear-gradient(90deg, var(--lightBlueColor) 0%, var(--lightPurpleColor) 100%);
}
.section-already .captain-section-tile .card {
  background: var(--lightPurpleColor);
background: linear-gradient(90deg, var(--lightPurpleColor) 0%, var(--lightRedishColor) 100%);
}
.section-datepassed .captain-section-tile .card {
  background: var(--lightRedishColor);
background: linear-gradient(90deg, var(--lightRedishColor) 0%, var(--lightYellowColor) 100%);
}

.captain-section-tile.loggedIn-active .card{
  position: relative;
  overflow: hidden;
}
.captain-section-tile.loggedIn-active .card:after {
  content: " Edit ";
  visibility: hidden;
  position:absolute;
  top:0;
  left: auto;
  right:41%;
  width: auto;
  transition: all ease-out .3s;
  transform: translateY(-30%);
  background-color: rgba(0,0,0, .7);
  color: #fff;
  padding: .4rem .8rem;
  font-size: .8rem;
  cursor: pointer;
  pointer-events: auto; 
}
.captain-section-tile.loggedIn-active .card:hover:after {
  visibility: visible;
  transform: translateY(30%);
}


.main-content-view .captain-section h2{
  /* background: #fff; */
  color: #313131;
  padding: .6rem 1rem .9rem;
  text-transform: capitalize;
}

.actionbar-sticky-top {
  background: var(--headerBgColor);
  background: linear-gradient(90deg, rgb(103, 121, 139) 0%, rgb(106, 150, 103) 100%);

  padding: 1rem 2rem;
  margin-bottom: 1rem;
  color: #fff;
}

.sticky-top.actionbar-sticky-top {
  top: var(--headerHeight);
  /* margin: 0 -15px; */
}


.footer {
  /* background: #C4317B;
    color: #fff; */
  background: var(--lightMudBrownColor);
  
  background: var(--lightMudBrownColor);
  background: linear-gradient(90deg, rgb(233, 232, 232) 0%, var(--lightMudBrownColor) 20%, var(--lightMudBrownColor) 80%, rgb(233, 232, 232) 100%);
  color: #313131;
  font-size: .9rem;

    padding: 1rem 0 0;
    overflow: hidden;
    height: 100%;
    min-height: 5vh;
    display: grid;
    grid-template-rows: repeat(2, 36px);
    grid-row-gap: .1rem;
    border-top: 1px solid var(--mudBrownColor);
}

.footer-partner-block {
  width: 100%;
  border-top: 1px solid rgb(231, 231, 231);
  background: rgb(233, 232, 232);

}

.footer-partner-block {
  opacity: .7;
}

.app-datepicker > div {
  margin-top: 0;
}

.avatar-wrap {
  display: inline-block;
  border-radius: 50% 50%;
  overflow: hidden;
  width: 130px;
  height: 100%;
  margin: .8rem auto 0;
}

@media (max-width: 755px) {
  .App {
    /* width: 90%; */
  }

  .header {
    min-height: 55px;
    padding: 0 1rem;
  }

    
  .logo {
    font-size: 1.6rem;
  }

  .sticky-top.actionbar-sticky-top {
    top: 55px;
  }


  .avatar {
    width: auto !important;
    height: 130px;
  }
}

@import url(./styles/Loader.css);